import { render, staticRenderFns } from "./DrawerMoreCharge.vue?vue&type=template&id=070dd5cb&scoped=true"
import script from "./DrawerMoreCharge.vue?vue&type=script&lang=js"
export * from "./DrawerMoreCharge.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "070dd5cb",
  null
  
)

export default component.exports