<!-- 运营中心 运营配置 站点运营配置 -->
<template>
  <scroll-layout>
    <template slot="header">
      <ykc-search
        ref="YkcSearch"
        :data="searchData"
        :searchForm="searchParams"
        @click="handleSearchConfirm"
        @clean="handleSearchClean"></ykc-search>
    </template>
    <div class="motorcade-main-account">
      <ykc-table
        ref="YkcTable"
        :data="tableData"
        :columns="tableColumns"
        title="电站列表"
        operateFixedType="right"
        :operateButtons="tableOperateButtons.filter(item => item.enabled())">
        <ykc-pagination
          slot="pagination"
          ref="YkcPagination"
          :total="pageInfo.total"
          :current-page.sync="pageInfo.pageIndex"
          :page-size.sync="pageInfo.pageSize"
          @current-change="handlePaginationCurrentChange"
          @size-change="handlePaginationSizeChange"></ykc-pagination>
      </ykc-table>
    </div>
    <DrawerMoreCharge
      :currentData="currentData"
      :showDrawer="chargeDrawerVisible"
      @finish="searchTableList"
      @closeDrawer="chargeDrawerVisible = false"></DrawerMoreCharge>
    <DrawerSoc
      :currentData="currentData"
      :showDrawer="socDrawerVisible"
      @finish="searchTableList"
      @closeDrawer="socDrawerVisible = false"></DrawerSoc>
  </scroll-layout>
</template>

<script>
  import DrawerMoreCharge from './DrawerMoreCharge.vue';
  import DrawerSoc from './DrawerSoc.vue';
  import { marketManage } from '@/service/apis';
  import { code } from '@/utils';

  export default {
    name: 'siteOperationConfigList',
    components: {
      DrawerMoreCharge,
      DrawerSoc,
    },
    watch: {
      // 关闭弹窗，当前操作数据需要清空，以防下次使用老数据
      chargeDrawerVisible: 'resetCurrentData',
      socDrawerVisible: 'resetCurrentData',
    },
    data() {
      return {
        currentData: null,
        chargeDrawerVisible: false,
        socDrawerVisible: false,
        tableData: [],

        tableOperateButtons: [
          {
            enabled: () => code('operation:config:station:moregun'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.currentData = {
                      stationId: row.stationId,
                      name: row.name,
                      isMoreCharge: row.isMoreCharge,
                    };
                    this.chargeDrawerVisible = true;
                  }}>
                  双枪并充
                </ykc-button>
              );
            },
          },
          {
            enabled: () => code('operation:config:station:soc'),
            render: (h, button, { row }) => {
              return (
                <ykc-button
                  type="text"
                  class="do-enable-btn"
                  onClick={() => {
                    this.currentData = {
                      stationId: row.stationId,
                      name: row.name,
                      soc: row.soc,
                    };
                    this.socDrawerVisible = true;
                  }}>
                  SOC上限
                </ykc-button>
              );
            },
          },
        ],
        tableColumns: [
          { label: '电站编码', prop: 'stationCode', minWidth: '200px' },
          {
            label: '电站名称',
            prop: 'name',
            minWidth: '250px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.name} placement="top-start">
            //         <span>{row.name ?? '--'}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          {
            label: '归属商户',
            prop: 'operatorName',
            minWidth: '250px',
            // scopedSlots: {
            //   default: ({ row }) => {
            //     return (
            //       <el-tooltip effect="dark" content={row.operatorName} placement="top-start">
            //         <span>{row.operatorName ?? '--'}</span>
            //       </el-tooltip>
            //     );
            //   },
            // },
          },
          {
            label: '即插即充',
            prop: 'isAppoint',
            minWidth: '100',
            scopedSlots: {
              default: ({ row }) => {
                if (row.isPrivatePile) {
                  const isSupportPlugCharge = row.isSupportPlugCharge === 1 ? '开' : '关';
                  const customClass =
                    row.isSupportPlugCharge === 1
                      ? 'appoint appoint-open'
                      : 'appoint appoint-close';
                  return code('operation:config:station:charge') ? (
                    <div
                      class={customClass}
                      title={isSupportPlugCharge}
                      onClick={() => this.updatePlugCharge(row)}>
                      <div class="appoint-txt">{isSupportPlugCharge}</div>
                      <div class="appoint-radius"></div>
                    </div>
                  ) : (
                    isSupportPlugCharge
                  );
                }
                return null;
              },
            },
          },
          {
            label: '预约充电',
            prop: 'isAppoint',
            minWidth: '100',
            scopedSlots: {
              default: ({ row }) => {
                const isAppointTxt = row.isAppoint === 1 ? '开' : '关';
                const customClass =
                  row.isAppoint === 1 ? 'appoint appoint-open' : 'appoint appoint-close';
                return code('operation:config:station:charge') ? (
                  <div
                    class={customClass}
                    title={isAppointTxt}
                    onClick={() => this.updateAppoint(row)}>
                    <div class="appoint-txt">{isAppointTxt}</div>
                    <div class="appoint-radius"></div>
                  </div>
                ) : (
                  isAppointTxt
                );
              },
            },
          },
          {
            label: '双枪并充',
            prop: 'isMoreCharge',
            scopedSlots: {
              default: ({ row }) => {
                const isMoreChargeTxt = row.isMoreCharge === 1 ? '已配置' : '未配置';
                return (
                  <div>
                    <span title={isMoreChargeTxt}>{isMoreChargeTxt}</span>
                  </div>
                );
              },
            },
            minWidth: '100',
          },
          {
            label: 'SOC上限',
            prop: 'soc',

            scopedSlots: {
              default: ({ row }) => {
                return (
                  <div>
                    <span title={row.soc || 0}>{row.soc || 0}</span>
                  </div>
                );
              },
            },
            minWidth: '100',
          },
          { label: '修改人', prop: 'updatePerson', minWidth: '150' },
          { label: '修改时间', prop: 'modifiedTime', minWidth: '160px' },
        ],
        pageInfo: {
          total: 0,
          pageIndex: 1,
          pageSize: 10,
        },
        searchParams: {
          name: '',
          operatorName: '',
          isAppoint: '',
          isMoreCharge: '',
          type: 1,
        },
      };
    },
    computed: {
      searchData() {
        return [
          {
            comName: 'YkcInput',
            key: 'name',
            label: '电站名称',
            placeholder: '请输入电站名称',
          },
          {
            comName: 'YkcInput',
            key: 'operatorName',
            label: '归属商户',
            placeholder: '请输入归属商户',
          },
          {
            comName: 'YkcDropdown',
            key: 'isAppoint',
            label: '预约充电',
            placeholder: '请输入预约充电',
            data: [
              { id: 0, name: '否' },
              { id: 1, name: '是' },
            ],
          },
          {
            comName: 'YkcDropdown',
            key: 'isMoreCharge',
            label: '双枪并充',
            placeholder: '请选择双枪并充',
            data: [
              { id: 0, name: '未配置' },
              { id: 1, name: '已配置' },
            ],
          },
        ];
      },
    },
    created() {
      this.searchTableList();
    },
    methods: {
      updatePlugCharge(row) {
        marketManage
          .updatePlugCharge({
            stationId: row.stationId,
            isSupportPlugCharge: row.isSupportPlugCharge === 1 ? 0 : 1,
          })
          .then(() => {
            this.searchTableList();
          });
      },
      updateAppoint(row) {
        marketManage
          .updateStationIsAppoint({
            stationId: row.stationId,
            isAppoint: row.isAppoint === 1 ? 0 : 1,
          })
          .then(() => {
            this.searchTableList();
          });
      },
      // 重置编辑信息
      resetCurrentData(val) {
        if (!val) {
          this.currentData = null;
        }
      },
      searchTableList() {
        const reqParams = {
          ...this.searchParams,
          current: this.pageInfo.pageIndex,
          size: this.pageInfo.pageSize,
        };
        marketManage
          .stationFindByPage(reqParams)
          .then(res => {
            this.tableData = res.records || [];
            this.pageInfo.total = res.total;
          })
          .catch(() => {
            this.tableData = [];
            this.pageInfo.total = 0;
          });
      },

      /** 点击清空查询按钮 */
      handleSearchClean(form) {
        Object.assign(this.searchParams, form);
      },

      /** 点击查询按钮 */
      handleSearchConfirm() {
        this.pageInfo.pageIndex = 1;
        this.searchTableList();
      },
      /** 分页 change 事件监听器 */
      handlePaginationCurrentChange(current) {
        this.pageInfo.pageIndex = current;
        this.searchTableList();
      },
      /** 分页 size  change  事件监听器 */
      handlePaginationSizeChange(size) {
        this.pageInfo.pageIndex = 1;
        this.pageInfo.pageSize = size;
        this.searchTableList();
      },
    },
  };
</script>

<style lang="scss" scoped>
  .appoint {
    width: 44px;
    height: 20px;
    border-radius: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0 4px;
    cursor: pointer;
    transition: all 0.3s ease;
    &-txt {
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      margin: 0 6px;
      font-size: 10px;
      transform: scale(0.83333);
      transform-origin: center center;
    }
    &-radius {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #ffffff;
    }
  }
  .appoint-open {
    background: #1779fe;
    flex-direction: row;
  }
  .appoint-close {
    background: #4a4a4a;
    flex-direction: row-reverse;
  }
</style>
